import * as React from "react"
import { useState } from "react"
import { Collapse, Modal } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const AddIncomingMoney = ({ showAddIncomingForm, setShowAddIncomingForm, getIncomingList }) => {
  const [accountType, setAccountType] = useState("")
  const [showStripeModal, setShowStripeModal] = useState(false)
  const [venmoUserId, setVenmoUserId] = useState("")
  const [paypalEmail, setPaypalEmail] = useState("")

  const handleClose = () => setShowStripeModal(false)
  const handleShow = () => {
    AxiosInstance.get("/setting-secure/connect-to-stripe").then(response => {
      window.open(response.data.url, "_self")
    })
  }

  const addIncomingMoneyMethod = () => {
    return new Promise(async () => {
      if (!accountType) {
        alert("Please select Incoming Account Method.")
        return false
      }

      if (accountType !== "Stripe") {
        var incomingMoneyObjData = {
          name: accountType,
        }

        if (accountType === "Venmo") {
          incomingMoneyObjData.venmo_user_id = venmoUserId
        } else if (accountType === "Paypal") {
          incomingMoneyObjData.paypal_email = paypalEmail
        }

        await AxiosInstance.post(`/setting-secure/add`, incomingMoneyObjData)
          .then(function (response) {
            if (response.status === 201) {
              setAccountType("")
              getIncomingList()
              console.log("success")
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    })
  }

  return (
    <div className="row">
      <Collapse in={showAddIncomingForm}>
        <div className="col-lg-7 col-md-10 mb-3 mx-auto addNewPaymentAccountContainer">
          <div id="FrmAddNewfinancialAccount" className="form-horizontal">
            <div className="row ml-0 mr-0">
              <div className="col-lg-12 colHeading">
                <strong>
                  <i className="bi bi-box-arrow-in-right" /> Incoming Money Settings
                </strong>
              </div>
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3 addNewGrayRoundBorder">
                      <div className="row align-items-center">
                        <label htmlFor="account_type" className="col-sm-4 col-form-label">
                          <i className="bi bi-currency-dollar" /> Incoming Account(s)
                        </label>
                        <div className="col-sm-8">
                          <select
                            id="account_type"
                            className="form-select"
                            value={accountType}
                            onChange={e => {
                              setAccountType(e.target.value)
                            }}
                          >
                            <option value="">== INCOMING ACCOUNT METHODS ==</option>
                            <option value="Stripe">Stripe</option>
                            <option value="Venmo">Venmo</option>
                            <option value="Paypal">Paypal</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-3 row align-items-center accountTypeOpt ACH_Container d-none">
                        <div className="col-sm-12">
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="bank_name" className="col-sm-4 col-form-label">
                              Bank Name{" "}
                              <span
                                data-bs-toggle="tooltip"
                                title="Just for you - i.e. ABC Bank Business Checking"
                              >
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="bank_name" className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="routing" className="col-sm-4 col-form-label">
                              Routing #
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="routing" name="routing" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <label htmlFor="account_no" className="col-sm-4 col-form-label">
                              Account #
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="account_no" name="account_no" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 row align-items-center accountTypeOpt ACH_plaid_Container d-none">
                        <div className="col-sm-12">
                          <div className="row align-items-center">
                            <label htmlFor="bank_name" className="col-sm-4 col-form-label" />
                            <div className="col-sm-8">
                              <button id="linkButton" className="btn btn-secondary">
                                Connect your bank account
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 row align-items-center accountTypeOpt DebitCard_Container d-none">
                        <div className="col-sm-12">
                          <div className="row align-items-center">
                            <div className="col-sm-12">
                              <div className="card-wrapper" />
                              <div className="form-container mt-3">
                                <input
                                  className="form-control mb-3"
                                  placeholder="Card number"
                                  type="tel"
                                  name="cc_number"
                                  id="cc_number1"
                                />
                                <input
                                  className="form-control mb-3"
                                  placeholder="Full name"
                                  type="text"
                                  name="cc_name"
                                  id="cc_name1"
                                />
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      className="form-control"
                                      placeholder="MM/YY"
                                      type="tel"
                                      name="cc_expiration"
                                      id="cc_expiration1"
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <input
                                      className="form-control"
                                      placeholder="CVV"
                                      type="number"
                                      name="cc_CVV"
                                      id="cc_CVV1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 row align-items-center accountTypeOpt Paypal_Container d-none">
                        <div className="col-sm-12">
                          <div className="row align-items-center">
                            <label htmlFor="paypal_email" className="col-sm-4 col-form-label">
                              Paypal Email{" "}
                              <span
                                data-bs-toggle="tooltip"
                                title="Email address associated with your PayPal account"
                              >
                                <i className="bi bi-question-circle-fill" />
                              </span>
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="paypal_email" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>

                      {accountType === "Stripe" && (
                        <>
                          <div className="mt-3 row align-items-center accountTypeOpt AddStripe_Container">
                            <div className="col-sm-12">
                              <div className="row align-items-center">
                                <label className="col-sm-4 col-form-label" />
                                <div className="col-sm-8">
                                  <a className="btn btnStripe" onClick={() => handleShow()}>
                                    <i className="bi bi-stripe" /> &nbsp; Connect with Stripe
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {accountType === "Venmo" && (
                        <>
                          <div className="mt-3 row align-items-center accountTypeOpt AddStripe_Container">
                            <div className="col-sm-12">
                              <div className="row align-items-center">
                                <label htmlFor="account_type" className="col-sm-4 col-form-label">
                                  Venmo Username / ID
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    name="paypalEmail"
                                    id="paypalEmail"
                                    className="form-control"
                                    onChange={e => {
                                      setVenmoUserId(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {accountType === "Paypal" && (
                        <>
                          <div className="mt-3 row align-items-center accountTypeOpt AddStripe_Container">
                            <div className="col-sm-12">
                              <div className="row align-items-center">
                                <label htmlFor="account_type" className="col-sm-4 col-form-label">
                                  Paypal Email
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="text"
                                    name="paypalEmail"
                                    id="paypalEmail"
                                    className="form-control"
                                    onChange={e => {
                                      setPaypalEmail(e.target.value)
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="mt-3 row align-items-center accountTypeOpt AddAdyen_Container d-none">
                        <div className="col-sm-12">
                          <div className="mb-3 row align-items-center">
                            <label className="col-sm-4 col-form-label" />
                            <div className="col-sm-8">
                              <input
                                type="checkbox"
                                name="adyen_test_mode"
                                id="adyen_test_mode"
                                value="1"
                                className="css-checkbox mr-sm-2"
                              />
                              <label htmlFor="adyen_test_mode" className="css-checkbox-label">
                                Sandbox Testing Mode
                              </label>
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="merchant_account" className="col-sm-4 col-form-label">
                              Merchant Account
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="merchant_account" className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="api_username" className="col-sm-4 col-form-label">
                              API Username
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="api_username" className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="api_password" className="col-sm-4 col-form-label">
                              API Password
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="api_password" className="form-control" />
                            </div>
                          </div>
                          <div className="mb-3 row align-items-center">
                            <label htmlFor="api_key" className="col-sm-4 col-form-label">
                              API Key
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="api_key" className="form-control" />
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <label htmlFor="notification_key" className="col-sm-4 col-form-label">
                              Notification Key
                            </label>
                            <div className="col-sm-8">
                              <input type="text" id="notification_key" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <label className="col-sm-4" />
                      <div className="col-sm-8">
                        <button
                          type="button"
                          className="btn btn-default btnCancelAddNewAccount"
                          onClick={() => setShowAddIncomingForm(!showAddIncomingForm)}
                        >
                          <i className="bi bi-x-lg" /> Cancel
                        </button>{" "}
                        <button
                          type="button"
                          className="btn btn-primary btnAddAccount"
                          onClick={addIncomingMoneyMethod}
                        >
                          <i className="bi bi-check2" /> Add Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showStripeModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <h2 className="mb-0">
                <i className="bi bi-stripe" /> Connect with Stripe
              </h2>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12">https://stripe.com/</div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Collapse>
    </div>
  )
}

export default AddIncomingMoney
